import React, { Component } from "react";
import { Row, Col } from "styled-bootstrap-grid";
import Lightbox from "react-images";
import Img from "gatsby-image";

import RelativeCol from "components/shared/RelativeCol";
import Container from "components/shared/Container";

import Info from "./components/Info";
import {
  Wrapper,
  Details,
  Title,
  MainPhoto,
  Content,
  More,
  Description,
} from "./styled";

class Item extends Component {
  state = {
    lightboxIsOpen: false,
    currentImage: 0,
  };

  openLightbox = (event) => {
    event.preventDefault();

    this.setState({
      lightboxIsOpen: true,
    });
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  };

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  };

  gotoImage = (index) => {
    this.setState({
      currentImage: index,
    });
  };

  render() {
    const {
      title,
      location,
      owner,
      cm,
      completion,
      architect,
      reverse,
      cover,
      photos,
      id,
      description,
    } = this.props;

    const gallery = photos.map((photo) => ({
      src: photo.node.childImageSharp.fluid.src,
    }));

    console.log(gallery);

    return (
      <Wrapper id={id}>
        <Container>
          <Content reverse={reverse}>
            <Col md={6}>
              <Details>
                <Row>
                  <Col lg={12}>
                    <Title>{title}</Title>
                    <Description
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </Col>
                  <Col lg={6}>
                    <Info label="Location" value={location} />
                    <Info label="Owner/Developer" value={owner} />
                    <Info label="CM" value={cm} />
                  </Col>
                  <Col lg={6}>
                    <Info label="Completion" value={completion} />
                    <Info label="Architect" value={architect} />
                  </Col>
                </Row>

                <More onClick={this.openLightbox}>More details</More>
              </Details>
            </Col>
            <RelativeCol md={6} center>
              <MainPhoto reverse={reverse} onClick={this.openLightbox}>
                <Img fluid={cover} />
              </MainPhoto>
            </RelativeCol>
          </Content>
        </Container>

        {photos.length && (
          <Lightbox
            images={gallery}
            isOpen={this.state.lightboxIsOpen}
            currentImage={this.state.currentImage}
            onClickNext={this.gotoNext}
            onClickPrev={this.gotoPrevious}
            onClose={this.closeLightbox}
            onClickThumbnail={this.gotoImage}
            backdropClosesModal
            width={2000}
          />
        )}
      </Wrapper>
    );
  }
}

export default Item;
