import React from 'react'
import { Row, Col } from 'styled-bootstrap-grid'
import SectionTitle from 'components/shared/SectionTitle'
import Container from 'components/shared/Container'
import Text from 'components/shared/Text'
import { OurWorkButton, VerticalStripe } from './styled'

const Header = () => (
  <>
    <Container relative>
      <VerticalStripe />

      <Row>
        <Col lg={6}>
          <SectionTitle main>Portfolio</SectionTitle>
          <Text main>
            Building close relationships with our customers is an integral part
            of our process.
          </Text>
          <Text>
            Through strong collaboration, dedication, and transparency – we
            design ambitious solutions to meet the highest standards.
          </Text>
          <OurWorkButton to="work" smooth={true} duration={500}>Our work</OurWorkButton>
        </Col>
      </Row>
    </Container>
  </>
)

export default Header
