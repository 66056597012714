import styled from 'styled-components'
import Button from 'components/shared/Button'
import { media } from 'themes';

export const OurWorkButton = styled(Button).attrs({
  type: 'text',
  isScrollLink: true,
  arrow: 'down',
  href: '#'
})`
  margin-top: 50px;
  margin-bottom: 70px;

  ${media.sm`
    margin-top: 16px;
  `}
`

export const VerticalStripe = styled.div`
  position: absolute;
  bottom: 0;
  right: 250px;
  width: 70px;
  height: 206px;
  background: linear-gradient(0deg, rgba(0,168,229,0) -200%, #00A8E5 100%);

  ${media.md`
    display: none;
  `}
`