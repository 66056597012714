import React from "react";
import { Element } from "react-scroll";
import { graphql } from "gatsby";

import projects from "config/projects";
import Layout from "components/shared/Layout";
import ContactLink from "components/shared/ContactLink";
import Header from "components/Portfolio/Header";
import Item from "components/Portfolio/Item";
import ScaProjects from "../components/Portfolio/ScaProjects";

const Portfolio = ({ data }) => {
  return (
    <Layout>
      <Header />

      <Element name="work">
        {projects.map((item, i) => {
          const cover = data[`${item.name}Cover`].childImageSharp.fluid;

          return (
            <Item
              key={item.name}
              description={item.description}
              id={item.name}
              title={item.title}
              location={item.location}
              owner={item.owner}
              cm={item.cm}
              completion={item.completion}
              architect={item.architect}
              cover={cover}
              photos={data[item.name].edges}
              reverse={i % 2}
            />
          );
        })}
      </Element>

      <ScaProjects />

      <ContactLink />
    </Layout>
  );
};

export const FluidImage = graphql`
  fragment FluidImage on File {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

export const BigFluidImage = graphql`
  fragment BigFluidImage on File {
    childImageSharp {
      fluid(maxHeight: 1500, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

export const query = graphql`
  query SmallImages {
    ThePlatinumCondoCover: file(
      relativePath: { eq: "projects/ThePlatinumCondo/OurWork/big.jpg" }
    ) {
      ...FluidImage
    }

    CunyCover: file(relativePath: { eq: "projects/Cuny/OurWork/big.jpg" }) {
      ...FluidImage
    }

    HighlineCover: file(
      relativePath: { eq: "projects/Highline/OurWork/big.jpg" }
    ) {
      ...FluidImage
    }

    MarshakCover: file(
      relativePath: { eq: "projects/Marshak/OurWork/big.jpg" }
    ) {
      ...FluidImage
    }

    MedgarCover: file(relativePath: { eq: "projects/Medgar/OurWork/big.jpg" }) {
      ...FluidImage
    }

    MemorialSloanCover: file(
      relativePath: { eq: "projects/MemorialSloan/OurWork/big.jpg" }
    ) {
      ...FluidImage
    }

    PacificParkCover: file(
      relativePath: { eq: "projects/PacificPark/OurWork/big.jpg" }
    ) {
      ...FluidImage
    }

    RiversideCenterCover: file(
      relativePath: { eq: "projects/RiversideCenter/OurWork/big.jpg" }
    ) {
      ...FluidImage
    }

    VoorheesCover: file(
      relativePath: { eq: "projects/Voorhees/OurWork/big.jpg" }
    ) {
      ...FluidImage
    }

    BarbizonHotelCover: file(
      relativePath: { eq: "projects/BarbizonHotel/OurWork/big.jpg" }
    ) {
      ...FluidImage
    }

    MomaCover: file(relativePath: { eq: "projects/Moma/OurWork/big.jpg" }) {
      ...FluidImage
    }

    PacificParkBuildingCover: file(
      relativePath: { eq: "projects/PacificParkBuilding/OurWork/big.jpg" }
    ) {
      ...FluidImage
    }

    NormandyCover: file(
      relativePath: { eq: "projects/Normandy/OurWork/big.jpg" }
    ) {
      ...FluidImage
    }

    OneWallStreetCover: file(
      relativePath: { eq: "projects/OneWallStreet/OurWork/big.jpg" }
    ) {
      ...FluidImage
    }

    OneWestEndCover: file(
      relativePath: { eq: "projects/1WestEnd/OurWork/big.jpg" }
    ) {
      ...FluidImage
    }

    ThePlatinumCondo: allFile(
      filter: {
        relativeDirectory: { eq: "projects/ThePlatinumCondo/Portfolio" }
      }
    ) {
      edges {
        node {
          ...BigFluidImage
        }
      }
    }

    Cuny: allFile(
      filter: { relativeDirectory: { eq: "projects/Cuny/Portfolio" } }
    ) {
      edges {
        node {
          ...BigFluidImage
        }
      }
    }

    Highline: allFile(
      filter: { relativeDirectory: { eq: "projects/Highline/Portfolio" } }
    ) {
      edges {
        node {
          ...BigFluidImage
        }
      }
    }

    Marshak: allFile(
      filter: { relativeDirectory: { eq: "projects/Marshak/Portfolio" } }
    ) {
      edges {
        node {
          ...BigFluidImage
        }
      }
    }

    Medgar: allFile(
      filter: { relativeDirectory: { eq: "projects/Medgar/Portfolio" } }
    ) {
      edges {
        node {
          ...BigFluidImage
        }
      }
    }

    MemorialSloan: allFile(
      filter: { relativeDirectory: { eq: "projects/MemorialSloan/Portfolio" } }
    ) {
      edges {
        node {
          ...BigFluidImage
        }
      }
    }

    PacificPark: allFile(
      filter: { relativeDirectory: { eq: "projects/PacificPark/Portfolio" } }
    ) {
      edges {
        node {
          ...BigFluidImage
        }
      }
    }

    RiversideCenter: allFile(
      filter: {
        relativeDirectory: { eq: "projects/RiversideCenter/Portfolio" }
      }
    ) {
      edges {
        node {
          ...BigFluidImage
        }
      }
    }

    Voorhees: allFile(
      filter: { relativeDirectory: { eq: "projects/Voorhees/Portfolio" } }
    ) {
      edges {
        node {
          ...BigFluidImage
        }
      }
    }

    BarbizonHotel: allFile(
      filter: { relativeDirectory: { eq: "projects/BarbizonHotel/Portfolio" } }
    ) {
      edges {
        node {
          ...BigFluidImage
        }
      }
    }

    Moma: allFile(
      filter: { relativeDirectory: { eq: "projects/Moma/Portfolio" } }
    ) {
      edges {
        node {
          ...BigFluidImage
        }
      }
    }

    PacificParkBuilding: allFile(
      filter: {
        relativeDirectory: { eq: "projects/PacificParkBuilding/Portfolio" }
      }
    ) {
      edges {
        node {
          ...BigFluidImage
        }
      }
    }

    Normandy: allFile(
      filter: { relativeDirectory: { eq: "projects/Normandy/Portfolio" } }
    ) {
      edges {
        node {
          ...BigFluidImage
        }
      }
    }

    OneWallStreet: allFile(
      filter: { relativeDirectory: { eq: "projects/OneWallStreet/Portfolio" } }
    ) {
      edges {
        node {
          ...BigFluidImage
        }
      }
    }

    OneWestEnd: allFile(
      filter: { relativeDirectory: { eq: "projects/1WestEnd/Portfolio" } }
    ) {
      edges {
        node {
          ...BigFluidImage
        }
      }
    }
  }
`;

export default Portfolio;
