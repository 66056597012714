import styled from 'styled-components'
import { Row } from 'styled-bootstrap-grid'
import Button from 'components/shared/Button'
import { media } from 'themes';

export const Wrapper = styled.div`
  background: linear-gradient(0deg, #ffffff 0%, #f7f7f7 100%);
  margin-bottom: 150px;

  ${media.sm`
    background: transparent;
    margin-bottom: 30px;
  `}
`

export const Details = styled.div`
  padding: 56px 0;
`

export const Title = styled.div`
  font-size: 24px;
  line-height: 29px;
  font-weight: 500;
  margin-bottom: 14px;
`

export const MainPhoto = styled.div`
  width: 100%;
  margin-top: -85px;

  &:hover {
    cursor: pointer;
  }

  ${media.xl`
    padding-left: 215px;

    ${({ reverse }) => reverse && `
      padding-right: 215px;
      padding-left: 0;
    `}
  `}

  ${media.lg`
    ${({ reverse }) => reverse && `
      float: right;
    `}
  `}

  ${media.sm`
    float: none;
    max-width: calc(100% + 30px);
    margin: 0 auto;
  `}
`

export const Content = styled(Row)`
  display: flex;

  ${media.sm`
    flex-direction: column-reverse;
  `}

  ${({ reverse }) => reverse && `
    flex-direction: row-reverse;
  `}
`

export const More = styled(Button).attrs({
  isStatic: true,
  type: 'text',
  href: '#'
})`
  margin-top: 32px;
`

export const Description = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 22px;
  margin-top: 14px;
  margin-bottom: 32px;
  opacity: 0.5;
`