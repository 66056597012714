import styled from "styled-components";

export const SContent = styled.div`
  h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 16px;
    margin-top: 0;
  }

  ul {
    margin-bottom: 40px;
    font-size: 18px;
    list-style: inside;

    li {
      line-height: 30px;
    }
  }
`;
