import React from "react";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { SContent } from "./styled";

const ScaProjects = () => {
  return (
    <Container>
      <Row>
        <Col mdOffset={2} md={9}>
          <SContent>
            <h2>NYC SCA Projects:</h2>
            <ul>
              <li>
                PS 254K, 2469 East 18thSt, Brooklyn NY 11235; GC: Technico
                Construction Services
              </li>
              <li>
                PS 203Q, 53-11 Springfield Blvd, Queens NY 11364; GC: Homeric
                Contracting Co.
              </li>
              <li>
                PS 169Q, 212-03 23rdAve, Queens NY 11360; GC: Technico
                Construction Services
              </li>
              <li>
                PS 206Q, 61-01 97th Place, Rego Park NY11374 GC: Technico
                Construction Services
              </li>
              <li>
                PS 127K, 922 78th Street, Brooklyn NY 11228; GC: The Morganti
                Group, Inc.
              </li>
              <li>
                PS 129Q, 128-05 9thAve, College Point NY 11356; GC: Technico
                Construction Services
              </li>
              <li>
                PS 101K, 8696 24thAve, Brooklyn NY 11214; GC: Turner
                Construction Company
              </li>
              <li>
                PS 343, 1 Peck Slip, New York NY 10038; GC: E.W. Howell Co., LLC
              </li>
              <li>
                PS 314Q, 88-08 164thSt, Jamaica NY 11432; GC: Silverite
                Construction Co., Inc.
              </li>
              <li>
                PS 315Q, 43-18 97thPl, Corona NY 11368; GC: Silverite
                Construction Co., Inc.
              </li>
              <li>
                PS 437K, 701 Caton Ave, Brooklyn, NY 11218; GC: Silverite
                Construction Co., Inc.
              </li>
              <li>
                Beacon High School, 521 W 43rdSt, New York NY 11036; GC: Skanska
                USA Building, Inc.
              </li>
              <li>
                PS 331K, 7002-20 4thAve, Brooklyn NY 11209; GC: Whitestone
                Construction Corp.
              </li>
              <li>
                PS 84K, 250 Berry Street,Brooklyn NY 11211; GC: Skyline
                Industries LLC
              </li>
              <li>
                PS 106K, 1328 Putnam Ave, Brooklyn NY 11212; GC: Admiral
                Construction LLC
              </li>
              <li>
                PS 264K, 371 89thStreet, Brooklyn NY 11209; GC: Whitestone
                Construction Corp.
              </li>
              <li>
                PS 277Q, 153-27 88thAve, Queens NY 11432; GC: Whitestone
                Construction Corp.
              </li>
              <li>
                PS 274K, 800 Bushwick Ave, Brooklyn NY 11221; GC: Whitestone
                Construction Corp.
              </li>
              <li>
                PS 263Q, 222-14 Jamaica Ave, Queens Village, NY 11428; GC:
                Hunter Roberts Construction
              </li>
            </ul>
          </SContent>
        </Col>
      </Row>
    </Container>
  );
};

export default ScaProjects;
