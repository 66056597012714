import { Col } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import { media } from 'themes'

const RelativeCol = styled(Col)`
  position: relative;

  ${media.sm`
    ${({ center }) => center && `
      text-align: center;
    `}
  `}
`

export default RelativeCol
